import { useCallback, createContext, useContext } from 'react';
import { useEntity } from 'components';
import invariant from 'tiny-invariant';
import { actionErrorAndThrow } from 'utilities';
import type { Group } from 'modules/core/types';
import { api } from 'services';
import useSWR from 'swr';

interface PermissionContextType {
  loading: boolean;
  permissions: Record<number, true>;
  toggle: (id: number, enable: boolean) => Promise<void>;
}

export const PermissionContext = createContext<undefined | PermissionContextType>(undefined);
export function usePermissionContext(): PermissionContextType {
  const ctx = useContext(PermissionContext);
  invariant(ctx);
  return ctx;
}

const empty = {};

export function useCreatePermissionsContext(): PermissionContextType {
  const { endpoint } = useEntity<Group>();
  const url = `${endpoint}permissions/`;

  const { isLoading, data, mutate } = useSWR<Record<number, true>, unknown>({
    url,
    method: 'GET',
  });
  const permissions: Record<number, true> = data || empty;

  // biome-ignore lint/correctness/useExhaustiveDependencies: endpoint should be enough
  const toggle = useCallback(
    async (id: number, enable: boolean) => {
      mutate((state) => ({ ...state }), { revalidate: false });
      const result = await api.request<Record<number, true>, unknown>({
        url,
        method: enable ? 'POST' : 'DELETE',
        body: { id },
      });

      if (result.ok) {
        mutate(result.data, { revalidate: false });
      } else {
        actionErrorAndThrow(result);
      }
    },
    [endpoint],
  );

  return {
    loading: isLoading,
    permissions,
    toggle,
  };
}
