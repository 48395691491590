import { date, state, timestamp } from 'components/columns';
import { EnterpriseLink, Period } from 'components';
import { BookingStateBadge, PaymentStateBadge, InvoiceSource } from '../common';
import { fmtMonth, sepFormat } from 'utilities';
import type { ListExpense } from './types';
import { CompanyTitle } from 'modules/core/company/components';
import { IsLinkedStateBadge } from '../common/IsLinkedStateBadge';
import { DocumentApplicationStateBadge } from '../common/DocumentApplicationStateBadge';
import { LinkApplicationStateBadge } from '../common/LinkApplicationStateBadge';
import { TableColumns } from '@ff-it/ui';

const sumProps = {
  width: 120,
  cellProps: {
    className: 'text-right',
  },
  headerProps: {
    className: 'text-right',
  },

  sortable: true,
};

export const columns: TableColumns<ListExpense> = [
  {
    content: 'Number',
    key: 'display_number',
    minWidth: 100,
    // absolute link since we use it in nested lists
    render: ({ id, number }: ListExpense) => <EnterpriseLink to={`/invoices/expense/${id}`}>{number}</EnterpriseLink>,
  },
  state<ListExpense>(),
  {
    key: '_',
    render: (i) => <InvoiceSource {...i} />,
    cellProps: {
      className: 'px-0 text-center',
    },
    width: 30,
  },
  {
    cellProps: {
      className: 'py-1',
    },
    content: 'Provider',
    key: 'partner.title',
    minWidth: 120,
    sortable: true,
    render: ({ partner }) => <CompanyTitle {...partner} />,
  },
  {
    content: 'Clients',
    key: 'clients',
    minWidth: 120,
    render: ({ clients }: ListExpense) => (
      <ul className=" m-0 list-unstyled">
        {clients.map(({ id, internal_name, title }) => (
          <li key={id}>{internal_name || title}</li>
        ))}
      </ul>
    ),
  },
  {
    cellProps: {
      className: 'py-0',
    },
    content: 'Finance per.',
    key: 'finance_period',
    render: ({ finance_period }) =>
      finance_period ? <Period date_from={finance_period.start} date_to={finance_period.end} fmt={fmtMonth} /> : null,
    width: 120,
  },
  timestamp('Created', 'created_at', { sortable: true }),
  { content: 'Type', key: 'type', width: 120, sortable: true },
  date('Date', 'checkout_date', { sortable: true }),
  date('P.date', 'payment_date', {
    sortable: true,
  }),
  {
    content: 'Sub total',
    key: 'sub_total',
    render: ({ sub_total }) => sepFormat(sub_total),
    width: 120,
    cellProps: {
      className: 'text-right',
    },
    sortable: true,
  },
  { content: 'Total', key: 'total', render: ({ total }) => sepFormat(total), ...sumProps },
  {
    width: '120px',
    cellProps: {
      className: 'px-0',
    },
    key: 'sums',
    render: (invoice: ListExpense) => (
      <>
        <PaymentStateBadge state={invoice.sums.paym_state} />
        <BookingStateBadge state={invoice.state} booking_state={invoice.booking_state} />
        <IsLinkedStateBadge linked_at={invoice.linked_at} link_state={invoice.sums.link_state} />
        <LinkApplicationStateBadge direction={invoice.direction} link_state={invoice.sums.link_state} />
        <DocumentApplicationStateBadge invoice={invoice} />
      </>
    ),
  },
];
